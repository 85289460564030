// Я хз, как перевести "Фишечки" на английский. Это лучшее название, которое я придумал
import React, {useEffect} from "react";
import {
    Box, Text, Image, Flex, Heading
} from "@chakra-ui/react";
import '../../fonts/fonts.css'
import {useTranslation} from "react-i18next";
import MetaTagsConstructor from "../Modules/MetaTagsConstructor";


export default function ThingsPage() {
    const { t, i18n } = useTranslation();
    // useEffect(() => {
    //     document.title = 'Фишечки CS2';
    //   }, []);
    return (
        <Flex justify='center' align='flex-start' flexWrap='warp' >
            <Text w='0' visibility='hidden' as='h1'>{t('things_h1')}</Text>
            <Text w='0' visibility='hidden' as='h2'>{t('things_h2')}</Text>
            <Box
                w='70%'
                // borderColor="podliva.400" 
                // borderWidth='2px' 
                // borderRadius='10px'

                mt='3%'
                >
                    
                <Flex 
                    justify='flex-start' 
                    bg='podliva.300' 
                    p='1%'
                    borderTopRadius='10px' 
                    alignItems='center'
                    flexDir='column'
                    boxShadow='dark-lg'>
                    <Heading as='h1' size='xl' color='podliva.800' w='50%' textShadow='0 0 20px black' >
                        {t('things_header')}
                    </Heading>
                    <Image boxSize='70%' boxShadow='outline' borderRadius='10px' mt='1%' src="https://www.savebutonu.com/wp-content/uploads/2020/10/resim_2020-10-24_192734.png"/>
                </Flex>
                
                <Box
                    flexWrap='warp' 
                    w='100%' 
                    bg='podliva.300'
                    p='1%'
                    borderBottomRadius='10px' 
                    alignItems='center'>
                    <Text pl='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        {t('things_text1')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('things_heading1')}
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">  
                        r_fullscreen_gamma 3
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        CS2 / Bind Jumpthrow CS2 
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">  
                        alias "+stetik" "+jump"<br/>
                        alias "+fonzi" "-attack; -attack2"<br/>
                        alias "+netti" "-jump"<br/>
                        bind "KEY" "+stetik; +fonzi; +netti"<br/>
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        Fast С4 drop
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        alias +c4 slot5;<br/>
                        alias -c4 drop;<br/>
                        bind KEY +c4;<br/>
                    </Text>                
                    <Heading as='h2' size='lg' color='podliva.800'>
                        Alias {t('things_heading2')}<br/>
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">  
                        alias +knife slot3<br/>
                        alias -knife lastinv<br/>
                        bind q +knife<br/>
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('things_heading3')}<br/>
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        alias +DJUMP "+jump; +duck"<br/>
                        alias -DJUMP "-jump; -duck"<br/>
                        bind space "+DJUMP"<br/>
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('things_heading4')}
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">  
                        bind "h" "incrementvar cl_radar_scale 0.45 1 0.55"<br/>
                        {t('things_text3')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        Включаем/выключаем follow recoil
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        bind n "incrementvar cl_crosshair_recoil 0 1 1"<br/>
                        {t('things_text4')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('things_heading5')}
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        engine_low_latency_sleep_after_client_tick 1<br/>
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('things_heading6')}
                    </Heading>
                    <Text  whiteSpace="pre-line" pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">  
                        {t('things_text5')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('things_heading6')}
                    </Heading>
                    <Text  whiteSpace="pre-line" pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">  
                        -allow_third_party_software +exec autoexec.cfg -novid -high -freq 144 -refresh 144 +fps_max 400 +rate 786432 -nojoy -threads 12
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('things_heading7')}
                    </Heading>
                    <Text  whiteSpace="pre-line" pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        bind "j" "incrementvar voice_modenable 0 1 1"<br/>
                        {t('things_text6')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('things_heading8')}
                        Мут вражеской команды по одной кнопке
                    </Heading>
                    <Text  whiteSpace="pre-line" pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        bind "h" "incrementvar cl_mute_enemy_team 0 1 1"<br/>
                        {t('things_text7')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('things_heading9')}
                    </Heading>
                    <Text  whiteSpace="pre-line" pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        {t('things_text8')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('things_heading10')}
                    </Heading>
                    <Text  whiteSpace="pre-line" pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        alias "+myjump" "+jump"<br/>
                        alias "-myjump" "-jump"<br/>
                        bind "mwheeldown" "+myjump; -myjump"<br/>
                        bind "mwheelup" "+myjump; -back; -myjump"<br/>
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('things_heading11')}
                    </Heading>
                    <Text  whiteSpace="pre-line" pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        alias _checkw "-back; -forward; alias checkw";<br/>
                        alias +w "+forward; alias checkw _checkw";<br/>
                        alias -w "checkw"; <br/>
                        bind w +w <br/>

                        alias +lj "+duck; +jump; checkw"<br/>
                        alias -lj "-duck; -jump; "<br/>
                        bind "mouse4" "+lj"<br/>
                    </Text>
                </Box>
                <MetaTagsConstructor url="thingsPage" />
            </Box>

        </Flex>
    )

}