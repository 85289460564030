import './App.css';
import React from "react";
import "./App.css";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Footer from './components/Modules/Footer';
import {Box, Grid, GridItem, Switch} from "@chakra-ui/react";
import HomePage from "./components/Pages/HomePage";
import Header from './components/Modules/Header';
import BestPage from './components/Pages/BestPage';
import GuidePage from './components/Pages/GuidePage';
import ThingsPage from './components/Pages/ThingsPage';
import ListPage from './components/Pages/ListPage';
function App() {
    return (
        <React.StrictMode>
            <Router>
                <Grid
                    w="100%"
                    h="100%"
                    templateAreas={`"header"
                                    "main"
                                    "footer"`}   
                    gridTemplateRows={'50px 1fr 60px'}
                    gridTemplateColumns={'1.5fr'}
                    overflow="auto"
                    gap='0'
                    color='blackAlpha.700'
                    fontWeight='bold'
                >
                    <GridItem bgGradient='radial( podliva.200, podliva.150)' area={'header'} >
                        <Header/>
                    </GridItem>
                    <GridItem bgGradient='radial( podliva.300, podliva.150)' area={'main'}>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/listPage" element={<ListPage />} />
                            <Route path="/bestPage" element={<BestPage />} />
                            <Route path="/guidePage" element={<GuidePage />} />
                            <Route path="/thingsPage" element={<ThingsPage />} />
                        </Routes>
                    </GridItem>
                    <GridItem pl='2' bg='podliva.100' area={'footer'}>
                        <Footer/>
                    </GridItem>
                </Grid>
            </Router>
        </React.StrictMode>
    );
}
{/* 
<GridItem pl='2' bg='podliva.100' area={'main'}>
    <Paginator/>
</GridItem>

                <Grid w="100vw" h="100vh" overflow="hidden" templateColumns="20% 80%" templateRows="90% 10%" gap={4}>
                    <Sidebar />

                    <Paginator gridColumnStart={2} justifySelf="center" alignSelf="end" />
                    <Footer gridColumnStart={2} gridColumnEnd={3} />
                </Grid> */}
 
export default App;
