module.exports = [
    // specify the default tags for all the routes here.
    // this will be used if the tags for given url is not specified here.
    {
        url: 'default',
        title: 'cs2skinsfree.ru - бесплатные скины для кс2 ',
        description: 'cs2skinsfree.ru - бесплатные скины кс 2, бесплатные скины cs 2 без депозита 2023, бесплатные скины в кс 2, бесплатные скины без депозита, бесплатные скины без депозита cs 2, кс 2 бесплатные скины без депозита, бесплатные скины кс 2 2023, бесплатные скины для кс 2, бесплатные скины, бесплатные скины без депозита 2023, бесплатные скины кс 2 без депозита 2023, бесплатные скины cs 2 2023, скины кс 2 халява, как получить скины в кс 2,сайты с халявой кс 2, фри ру, фри скинс, бесплатные кейсы кс 2, фри кейсы кс 2, фри стим,.',
        keywords: 'бесплатные скины, сайты кс2, кс2, кс2 рулетка, кс2 казино, кс2 кейсы, кс2 промокоды',
    },
    {
        url: 'listPage',
        title: 'cs2skinsfree.ru - Список сайтов CS2',
        description: 'cs2skinsfree.ru - бесплатные скины кс 2, бесплатные скины cs 2 без депозита 2023, бесплатные скины в кс 2, бесплатные скины без депозита, бесплатные скины без депозита cs 2, кс 2 бесплатные скины без депозита, бесплатные скины кс 2 2023, бесплатные скины для кс 2, бесплатные скины, бесплатные скины без депозита 2023, бесплатные скины кс 2 без депозита 2023, бесплатные скины cs 2 2023, скины кс 2 халява, как получить скины в кс 2,сайты с халявой кс 2, фри ру, фри скинс, бесплатные кейсы кс 2, фри кейсы кс 2, фри стим,.',
        keywords: 'бесплатные скины, сайты кс2, кс2, кс2 рулетка, кс2 казино, кс2 кейсы, кс2 промокоды',
    },
    {
        url: 'bestPage',
        title: 'cs2skinsfree.ru - Лучшие сайты CS2',
        description: 'cs2skinsfree.ru - бесплатные скины кс 2, бесплатные скины cs 2 без депозита 2023, бесплатные скины в кс 2, бесплатные скины без депозита, бесплатные скины без депозита cs 2, кс 2 бесплатные скины без депозита, бесплатные скины кс 2 2023, бесплатные скины для кс 2, бесплатные скины, бесплатные скины без депозита 2023, бесплатные скины кс 2 без депозита 2023, бесплатные скины cs 2 2023, скины кс 2 халява, как получить скины в кс 2,сайты с халявой кс 2, фри ру, фри скинс, бесплатные кейсы кс 2, фри кейсы кс 2, фри стим,.',
        keywords: 'бесплатные скины, сайты кс2, кс2, кс2 рулетка, кс2 казино, кс2 кейсы, кс2 промокоды',
    },
    {
        url: 'guidePage',
        title: 'cs2skinsfree.ru - Советы CS2',
        description: 'cs2skinsfree.ru - бесплатные скины кс 2, бесплатные скины cs 2 без депозита 2023, бесплатные скины в кс 2, бесплатные скины без депозита, бесплатные скины без депозита cs 2, кс 2 бесплатные скины без депозита, бесплатные скины кс 2 2023, бесплатные скины для кс 2, бесплатные скины, бесплатные скины без депозита 2023, бесплатные скины кс 2 без депозита 2023, бесплатные скины cs 2 2023, скины кс 2 халява, как получить скины в кс 2,сайты с халявой кс 2, фри ру, фри скинс, бесплатные кейсы кс 2, фри кейсы кс 2, фри стим,.',
        keywords: 'кс2 гайды, кс2 трюки, кс2 возможности, кс2 мануал, сайты кс2',
    },
    {
        url: 'thingsPage',
        title: 'cs2skinsfree.ru - Фишечки CS2',
        description: 'cs2skinsfree.ru - бесплатные скины кс 2, бесплатные скины cs 2 без депозита 2023, бесплатные скины в кс 2, бесплатные скины без депозита, бесплатные скины без депозита cs 2, кс 2 бесплатные скины без депозита, бесплатные скины кс 2 2023, бесплатные скины для кс 2, бесплатные скины, бесплатные скины без депозита 2023, бесплатные скины кс 2 без депозита 2023, бесплатные скины cs 2 2023, скины кс 2 халява, как получить скины в кс 2,сайты с халявой кс 2, фри ру, фри скинс, бесплатные кейсы кс 2, фри кейсы кс 2, фри стим,.',
        keywords: 'кс2 гайды, кс2 трюки, кс2 возможности, кс2 мануал, сайты кс2',
    },

];