import React, {Suspense} from "react";
import {
    Select,
    Text,
    Flex,
    Icon,
    Image,
    Link
} from "@chakra-ui/react";
import {AiFillTrophy} from "react-icons/ai";
import {MdHome} from "react-icons/md";
import {FaListAlt, FaQuestion} from "react-icons/fa";
import {GiP90} from 'react-icons/gi'
import '../../fonts/fonts.css'
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import LanguageSelector from "./SubModules/LanguageSelector";
import Loader from "./SubModules/Loader";
export default function Header() {
    const { t, i18n } = useTranslation();
    
    const NavItem = (props) => {

        const { icon, children, ...rest } = props;
        return (
            <Flex
            textShadow='1px 2px black'
                justify='center' 
                align="center"
                px="4"
                // pl="4"
                py="3"
                mb='1'
                ml='1px'
                mr='1px'
                h='100%' 
                cursor="pointer"
                color="podliva.600"
                _dark={{
                    color: "gray.400",
                    
                }}
                borderWidth='0px'
                borderColor='podliva.300'
                borderStyle='solid'
                borderRadius='5px'
                _hover={{
                    // bg: "podliva.400",
                    // bgGradient: 'linear( to-t, podliva.200, podliva.400)',
                    color: "podliva.700",
                    
                }}
                role="group"
                fontWeight="semibold"
                transition=".15s ease"
                {...rest}
            >
                {icon && (
                    <Icon
                        mx="2"
                        boxSize="5"
                        _groupHover={{
                            color: "podliva.700",
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        );
    };
    const len = '15%'
    const naviagte = useNavigate();

    
    return (
            <Flex w='100%' justify='center'>
                <Flex
                w='100%'
                h='8%'
                bg='podliva.300'
                direction="row"
                as="nav"
                fontSize="sm"
                color="gray.100"
                aria-label="Main Navigation"
                pos='fixed'
                zIndex='10'
                boxShadow='dark-lg'
                // borderBottomWidth='2px'
                // borderBottomColor='podliva.400'
                // borderBottomStyle='solid'
                // borderLeftWidth='2px'
                // borderLeftColor='podliva.400'
                // borderLeftStyle='solid'
                // borderBottomLeftRadius='10px'
                // borderRightWidth='2px'
                // borderRightColor='podliva.400'
                // borderRightStyle='solid'
                // borderBottomRightRadius='10px'
                justify='center'
                align='center'

                // bgGradient='linear( to-t, podliva.100, podliva.300)'
            >
                <Flex h='90%' w={len} justify='center' align='center' ml={len} mr='5%'>
                    {/* Лого и название */}
                    <Image  boxSize='80px' src='https://cs2skinsfree.ru/images/logo.png'/>
                    <Link href='https://cs2skinsfree.ru/'>
                        <Text userSelect='none' ml='15px'> CS2SKINSFREE </Text>
                    </Link>

                </Flex>
                <Flex w='95%' h='100%' justify='center' mr='20%'>
                    <NavItem onClick={() => naviagte('/')} icon={MdHome}>{t('main')}</NavItem>
                    <NavItem onClick={() => naviagte('/listPage')} icon={FaListAlt}>{t('sites_list')}</NavItem>
                    <NavItem onClick={() => naviagte('/bestPage')} icon={AiFillTrophy}>{t('best_sites')}</NavItem>
                    <NavItem onClick={() => naviagte('/guidePage')} icon={FaQuestion}>{t('guides')}</NavItem>
                    {/* <NavItem onClick={() => naviagte('/tradePage')} icon={GiTrade}>Обменники</NavItem> */}
                    <NavItem onClick={() => naviagte('/thingsPage')} icon={GiP90}>{t('things')}</NavItem>
                    <LanguageSelector i18n={i18n} color='black' w='7%'/>
                </Flex>
                </Flex>
            </Flex>

    )
}
