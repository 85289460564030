import React, { Component, Suspense } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import {Box, Flex, Link, Spacer, Text} from "@chakra-ui/react";
import ReactCountryFlag from "react-country-flag";
import Loader from "./SubModules/Loader";
// import "./Footer.css";

function Footer() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        console.log("Language Changed to: " + lng)
    };

    return (
                <Box as="footer" bg="podliva.100" >
                    <Flex align="center" justify="space-between" direction="row">
                        <Text fontSize="sm" color="podliva.500">Copyright © 2023 by CS2SKINSFREE.com | {t('all_rights_reserved')}. </Text>
                        <Flex mt="2">
                            {/* <Link href="#" color="podliva.500" mr="4">About</Link>
                            <Link href="#" color="podliva.500" mr="4">Contact</Link>
                            <Link href="#" color="podliva.500" mr="4">Privacy</Link> */}
                        </Flex>
                    </Flex>
                </Box>
    );
}


export default Footer;
