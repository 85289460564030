import React, {useEffect} from "react";
import {
    Button,
    Heading,
    Center,
    useToast, Box, Text, Image, Flex, Link, Icon, useStatStyles
} from "@chakra-ui/react";
import { list } from "../List";
import '../../fonts/fonts.css'
import { GiMammoth, GiPistolGun } from "react-icons/gi";
import { FaCopy, FaUserAlt } from "react-icons/fa";
import { SiSitepoint } from "react-icons/si"
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import MetaTagsConstructor from "../Modules/MetaTagsConstructor";
export default function HomePage()  {
    const naviagte = useNavigate();
    const { t, i18n } = useTranslation();
    // useEffect(() => {
    //     document.title = 'cs2skinsfree';
    //   }, []);
    return (
        <Flex h='100%' w='100%' as='main' justify='center' align='center' pb='10%'>
            <Text w='0' visibility='hidden' as='h1'>{t('home_h1')}</Text>
            <Text w='0' visibility='hidden' as='h2'>{t('home_h2')}</Text>
            <Flex h='100%' w='100%' mt='8%' flexDir='column' justify='center' align='center'>
                <Flex flexDir='column'>
                    <Flex ml='8%' mb='5%'>
                    {/* Верхний флекс с заголовокм*/}
                        <Flex w='40%' flexDir='column' mt='3%' ml='4%' >
                            <Flex w='60%' mt='3%'>
                                <Heading as='h1' size='xl' color='podliva.800' fontSize='40px' fontFamily="'PT Sans', sans-serif">
                                    {t('home_text1')}
                                </Heading>
                            </Flex>
                            <Flex w='50%' mt='3%'> 
                                <Text color='podliva.600'>
                                    {t('home_text2')}
                                </Text>
                            </Flex>
                            <Flex mt='5%' >
                                <Button onClick={() => naviagte('/listPage')} w='40%' color='podliva.100' bg='podliva.500'>
                                    {t('home_text3')}
                                </Button>
                            </Flex>
                        </Flex>
                            <Flex w='60%' justify='center' pr='10%'>
                                <Image htmlHeight='50%'  htmlWidth='70%' src="https://cs2skinsfree.ru/images/logo.png"></Image>
                            </Flex>
                        </Flex>
                        {/* Смешные надписи */}
                        <Flex justify='center' align='center' mt='1%'>
                            <Flex bg='podliva.300' justify='center' align='center' mr='5%' p='1%' borderRadius='15px' boxShadow='dark-lg' w='15%'>
                                <Flex bg='podliva.400' borderRadius='5px' p='2px' mr='5%' h='100%' w='18%' justify='center' align='center'>
                                    <Icon  boxSize='30px' as={FaUserAlt} color='podliva.700'/>
                                </Flex>
                                <Text  color='podliva.600'>
                                    {t('home_advantages1')}
                                </Text>
                            </Flex>
                            <Flex bg='podliva.300' justify='center' align='center' mr='5%' p='1%' borderRadius='15px' boxShadow='dark-lg' w='15%'>
                                <Flex bg='podliva.400' borderRadius='5px' p='2px' mr='5%' h='100%' w='13%' justify='center' align='center'>
                                    <Icon  boxSize='30px' as={SiSitepoint} color='podliva.700'/>
                                </Flex>
                                <Text  color='podliva.600'>
                                    {t('home_advantages2')}
                                </Text>
                            </Flex>
                            <Flex bg='podliva.300' justify='center' align='center' mr='5%' p='1%' borderRadius='15px' boxShadow='dark-lg' w='15%'>
                                <Flex bg='podliva.400' borderRadius='5px' p='2px' mr='5%' h='100%' w='13%' justify='center' align='center'>
                                    <Icon  boxSize='30px' as={FaCopy} color='podliva.700'/>
                                </Flex>
                                <Text  color='podliva.600'>
                                    {t('home_advantages3')}
                                </Text>
                            </Flex>
                            <Flex bg='podliva.300' justify='center' align='center' mr='5%' p='1%' borderRadius='15px' boxShadow='dark-lg' w='15%'>
                                <Flex bg='podliva.400' borderRadius='5px' p='2px' mr='5%' h='100%' w='13%' justify='center' align='center'>
                                    <Icon  boxSize='30px' as={GiPistolGun} color='podliva.700'/>
                                </Flex>
                                <Text  color='podliva.600'>
                                    {t('home_advantages4')}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Center w='70%' mt='10%' lineHeight='40px'>
                        <Text as='h1' color='podliva.700' justify='center' fontFamily="'PT Sans', sans-serif" fontSize='28'>
                            {t('site_disc')}
                        </Text>
                    </Center>
                    <MetaTagsConstructor url="default" />

            </Flex>
        </Flex >
    );
};
