import React, {useEffect} from "react";
import {Flex, Text} from "@chakra-ui/react";
import { list } from "../List";
import '../../fonts/fonts.css'
import SiteList from "../Modules/SiteList";
import Filter from "../Modules/Filter";
import { useTranslation } from "react-i18next";
import MetaTagsConstructor from "../Modules/MetaTagsConstructor";


export default function ListPage () {
    const [siteList, setSiteList] = React.useState(list) 
    const { t, i18n } = useTranslation();
    // useEffect(() => {
    //     document.title = 'Список сайтов CS2';
    //   }, []);
    return (
        <Flex as="main" justifyContent='center' flexDir='row'>
                <Text w='0' visibility='hidden' as='h1'>{t('list_h1')}</Text>
                <Text w='0' visibility='hidden' as='h2'>{t('list_h2')}</Text>
                <Filter setSiteList={setSiteList} siteList={siteList}/>
                <SiteList siteList={siteList}/>
                <MetaTagsConstructor url="listPage" />

        </Flex>
    );
};