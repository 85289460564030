import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import {BrowserRouter, BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { extendTheme } from "@chakra-ui/react"

import './i18n';

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
    colors: {
        podliva: {
            100: "#0f0f14",
            150: "#131218",
            200: "#16161d",
            300: "#25242d",
            400: "#5E5E5E",
            500: "#ff9e00",
            600: '#a8aab0',
            700: '#ffffff',
            800: '#ffffff'
        },
    },
})
// Оранжевый (Очень оранжево) "Мистер Апельсин"
// const theme = extendTheme({
//     colors: {
//         podliva: {
//             100: "#ff6d00",
//             150: "#ff7900",
//             200: "#ff8500",
//             300: "#ff9100",
//             400: "#ff9e00",
//             500: "#ff9e00",
//             600: '#ffffff',
//             700: '#FB8122',
//             800: '#ff9e00'
//         },
//     },
// })
// Хорошая тема, нечего сказать кроме как хорошая тема
// const theme = extendTheme({
//     colors: {
//         podliva: {
//             100: "#10002b",
//             150: "#190144",
//             200: "#240046",
//             300: "#3c096c",
//             400: "#2d6ae0",
//             500: "#e0aaff",
//             600: '#ffffff',
//             700: '#ff6d00',
//             800: '#ff9e00'
//         },
//     },
// })
// Фиолетовая тема
// const theme = extendTheme({
//     colors: {
//         podliva: {
//             100: "#e0aaff",
//             200: "#9d4edd",
//             300: "#5a189a",
//             400: "#3c096c",
//             500: "#10002b",
//         },
//     },
// })
// Красная тема
// const theme = extendTheme({
//     colors: {
//         podliva: {
//             100: "#ffffff",
//             200: "#b1a7a6",
//             300: "#ba181b",
//             400: "#161a1d",
//             500: "#0b090a",
//         },
//     },
// })
// Синяя тема 
// const theme = extendTheme({
//     colors: {
//         podliva: {
//             100: "#f0ebd8",
//             200: "#748cab",
//             300: "#3e5c76",
//             400: "#1d2d44",
//             500: "#0d1321",
//         },
//     },
// })
// Тема "Азимов"
// const theme = extendTheme({
//     colors: {
//         podliva: {
//             100: "#fffcf2",
//             200: "#ccc5b9",
//             300: "#403d39",
//             400: "#252422",
//             500: "#eb5e28",
//         },
//     },
// })
// Первая тема "Тинькофф"
// const theme = extendTheme({
//     colors: {
//         podliva: {
//             100: "#f6f4e6",
//             200: "#fddb3a",
//             300: "#52575d",
//             400: "#41444b",
//             500: "#35373b",
//         },
//     },
// })
// const theme = extendTheme({
//     colors: {
//         podliva: {
//             100: "#0D0D0D",
//             200: "#BA56DB",
//             300: "#76298F",
//             400: "#30420A",
//             500: "#608F00",
//         },
//     },
// })
/*
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    console.log("App: Hydrate");
    hydrate(<App />, rootElement);
} else {
    console.log("App: Render");
    render(<App />, rootElement);
}
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ChakraProvider theme={theme}>
          <App/>
      </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
