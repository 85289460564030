export const list = [
    {key: 1, rate: 5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/gamdom-logo.png', info: 'gamdom_info', link: 'https://ify.ac/z1y', promo: 'PODLIVA', tags: ['CS2', 'gambling', 'rakeback', 'roulette', 'crash', 'match-betting', 'slots', 'hilo', 'raffle', 'casino']},
    // https://gamdom.com/r/PODLIVA
    {key: 2, rate: 5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/csgoroll-logo.png',info: 'csgoroll_info', link: 'https://ify.ac/z1z', promo: 'PODLIVA', tags: ['CS2', 'gambling', 'bonus_deposit', 'bonus_case', 'roulette', 'crash', 'match-betting', 'battle', 'plinko', 'pocker', 'blackjack', 'minesweeper', 'cases', 'dice', 'upgrader']},
    // https://csgoroll.com/r/PODLIVA
    {key: 3, rate: 5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/csgoempire-logo.png',info: 'csgoempire_info', link: 'https://ify.ac/z20', promo: 'PODLIVA', tags: ['CS2', 'gambling', 'bonus_case', 'roulette', 'coinflip', 'raffle']},
     // https://csgoempire.com/r/PODLIVA
    {key: 4, rate: 4.5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/csgofast-logo.png',info: 'csgofast_info', link: 'https://ify.ac/z21', promo: 'PODLIVA', tags: ['CS2', 'gambling', 'bonus_deposit', 'bonus_case', 'hilo', 'roulette', 'battle', 'slots', 'pocker']},
    // https://csgofast.com?ref=PODLIVA
    {key: 5, rate: 5, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/datdrop-logo.png',info: 'datdrop_info', link: 'https://ify.ac/z22', promo: 'PODLIVA', tags: ['CS2', 'gambling', 'bonus_deposit', 'upgrade', 'battle']},
    // https://datdrop.com/p/PODLIVA
    {key: 9, rate: 5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/duelbits-logo.png',info: 'duelbits_info', link: 'https://ify.ac/z23', promo: 'PODLIVA', tags: ['gambling', 'roulette', 'dice', 'plinko', 'minesweeper', 'crash', 'battle', 'match-betting']},
    // https://duelbits.com/?a=PODLIVA
    {key: 10, rate: 5, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/daddyskins-logo.png',info: 'daddyskins_info', link: 'https://ify.ac/z24', promo: 'PODLIVA', tags: ['CS2', 'gambling', 'bonus_deposit', 'upgrade', 'cases', 'battle']},
    // https://daddyskins.com/promo-code/PODLIVA
    {key: 11, rate: 5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/keydrop-logo.png',info: 'keydrop_info', link: 'https://ify.ac/z25', promo: 'PODLIVA', tags: ['CS2', 'gambling', 'bonus_deposit', 'cases']},
    // https://key-drop.com/?code=PODLIVA
    {key: 12, rate: 5, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/csgoluck-logo.png',info: 'csgoluck_info', link: 'https://ify.ac/z27', promo: 'PODLIVA', tags: ['CS2', 'gambling', 'bonus_case', 'battle', 'case', 'coinflip', 'crash', 'mines', 'roulette']},
    // https://csgoluck.com/r/PODLIVA
    {key: 13, rate: 5, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/csgopolygon-logo.png',info: 'plgrubet_info', link: 'https://ify.ac/z28', promo: 'PODLIV', tags: ['CS2', 'gambling', 'bonus_balance', 'roulette', 'crash', 'battle', 'slots', 'dice']},
    // https://plgrubet.com/?r=PODLIV
    {key: 15, rate: 4.5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/csgo500-logo.png',info: 'csgo500_info', link: 'https://ify.ac/z29', promo: 'PODLIVA', tags: ['roulette', 'crash', 'bonus_deposit', 'crash', 'duels', 'slots', 'match-betting', 'plinko']},
    // https://500.casino/r/PODLIVA
    {key: 16, rate: 3.5, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/csgopositive-logo.png',info: 'csgopositive_info', link: 'https://ify.ac/z2B', promo: 'PODLIV', tags: ['CS2', 'DOTA2', 'gambling', 'rakeback', 'match-betting']},
    // https://csgopositive.me/p/podliv/
    {key: 17, rate: 4, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/rollbit-logo.png',info: 'rollbit_info', link: 'https://ify.ac/z2C', promo: 'PODLIVA', tags: ['CS2', 'roulette', 'crash', 'duels', 'rakeback']},
    // https://rollbit.com/referral/PODLIVA
    {key: 18, rate: 4, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/clashgg-logo.png',info: 'clash_gg_info', link: 'https://ify.ac/z2D', promo: 'PODLIVA', tags: ['CS2','roulette', 'crash', 'bonus_deposit', 'cases']},
    // https://clash.gg/r/PODLIVA
    {key: 19, rate: 3, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/csgobig-logo.png',info: 'csgobig_info', link: 'https://ify.ac/z2E', promo: 'PODLIVA', tags: ['CS2', 'roulette', 'coinflip', 'cases', 'duels', 'bonus_case' ]},
    // https://csgobig.com/r/podliva
    {key: 20, rate: 3, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/flamecases-logo.png',info: 'flamecases_info', link: 'https://ify.ac/z2H', promo: 'podliva', tags: ['CS2', 'duels', 'bonus_balance' ]},
    // https://flamecases.com/promo/podliva
    {key: 21, rate: 3, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/csgoskins-logo.png',info: 'csgoskins_info', link: 'https://ify.ac/z2I', promo: 'PODLIVA', tags: ['CS2', 'cases', 'upgrader','bonus_balance' ]},
    // https://csgo-skins.com
    {key: 22, rate: 3, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/knifex-logo.png',info: 'knifex_info', link: 'https://ify.ac/z2J', promo: 'PODLIVA', tags: ['CS2', 'cases', 'upgrader','bonus_case', 'crash', 'roulette' ]},
    // https://knifex.skin/ref/PODLIVA
    {key: 23, rate: 4, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/hellstore-logo.png',info: 'hellstore_info', link: 'https://ify.ac/z2M', promo: '901311', tags: ['CS2', 'roulette', 'crash' ]},
    // https://hellstore.me/r/901311
    {key: 24, rate: 3.5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/wtfskins-logo.png',info: 'wtfskins_info', link: 'https://ify.ac/z2O', promo: 'podliva', tags: ['CS2', 'roulette', 'crash', 'coinflip', 'upgrader', 'bonus_balance']},
    // https://www.wtfskins.com
    {key: 25, rate: 2.5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/farmskins-logo.png',info: 'farmskins_info', link: 'https://ify.ac/z2P', promo: 'PODLIVA', tags: ['CS2', 'roulette', 'crash', 'duels', 'upgrader', 'bonus_balance']},
    // https://farmskins.com/ref-PODLIVA
    {key: 26, rate: 4, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/rustclash-logo.png',info: 'rustclash_info', link: 'https://ify.ac/z2Q', promo: 'PODLIVA', tags: ['RUST', 'roulette', 'cases', 'bonus_deposit', 'upgrader', 'plinko' ]},
    // https://rustclash.com/r/PODLIVA
    {key: 27, rate: 2, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/howlgg-logo.png',info: 'howlgg_info', link: 'https://ify.ac/z2T', promo: 'podliva', tags: ['CS2', 'roulette', 'crash', 'bonus_balance']},
    // https://howl.gg/r/podliva 

    // {key: 28, rate: 2.5, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/hyperdrop-logo.png',info: 'hyperdrop_info', link: 'https://hyper-drop.com?code=PODLIVA', promo: 'PODLIVA', tags: ['CS2', 'cases', 'upgrader' ]}, <--- НЕ ДАЛО НИЧЕГО ПО РЕФЕРАЛКЕ
    // {key: 29, rate: 2.5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/givedrop-logo.png',info: 'givedrop_info', link: 'https://givedrop.net/p/give-365723', promo: 'give-365723', tags: ['CS2', 'roulette', 'crash' ]}, <--- САЙТ ПЕРЕЕХАЛ, РЕФЕРАЛКА СЛЕТЕЛА
    //

    ///
    // {key: 30, rate: 2.5, vpn: true, image: '666',info: 'r1skins_info', link: 'https://r1-skins.com/ref-podliva', promo: 'podliva', tags: ['CS2', 'roulette', 'crash' ]}, <--- ОПЯТЬ НЕ ДАЁТ НИЧЕГО ПО РЕФЕРАЛКЕ

    // TRADE САЙТЫ. N.B. Некоторые требуют опред стату сайта для получения рефералки (те добавим позже)
    // https://skinsmonkey.com/account/affiliates
    // https://lootbear.com/r/PODLIVA
    {key: 31, rate: 5, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/tradeitgg-logo.png',info: 'tradeitgg_info', link: 'https://ify.ac/z2U', promo: 'PODLIVA', tags: ['CS2', 'trade', 'cashout' ]},
    // https://tradeit.gg/?aff=PODLIVA
    {key: 32, rate: 5, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/lootbear-logo.png',info: 'lootbear_info', link: 'https://ify.ac/z2V', promo: 'PODLIVA', tags: ['CS2', 'trade', 'cashout' ]},
    // https://lootbear.com/r/PODLIVA

    // CASHOUT сайты

    {key: 33, rate: 4, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/skincashier-logo.png',info: 'skincashier_info', link: 'https://ify.ac/z2W', promo: 'PODLIVA', tags: ['trade', 'crash' ]},
    // https://skincashier.com/r/PODLIVA
    {key: 34, rate: 4, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/skinscash-logo.png',info: 'skinscash_info', link: 'https://ify.ac/z2Y', promo: '76561198305239633', tags: ['CS2', 'cashout', 'crash' ]},
    // https://skins.cash/user/ref/76561198305239633
    {key: 35, rate: 4, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/swapgg-logo.png',info: 'swapgg_info', link: 'https://ify.ac/z2b', promo: 'c2dLniVwS', tags: ['CS2', 'cashout', 'crash' ]},
    // https://swap.gg/?r=c2dLniVwSc
    {key: 36, rate: 3.5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/skinswap-logo.png',info: 'skinswap_info', link: 'https://ify.ac/z2d', promo: 'podliva', tags: ['CS2', 'RUST', 'cashout', 'crash' ]},
    // https://skinswap.com/r/podliva
    {key: 37, rate: 2.5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/csgoselly-logo.png',info: 'csgoselly_info', link: 'https://ify.ac/z2f', promo: 'PODLIVA', tags: ['CS2', 'cashout', 'crash' ]},
    // https://csgoselly.com/r/PODLIVA

    // СОМНИТЕЛЬНЫЕ САЙТЫ
    // https://skinauctions.com/r/podliva
    // {key: 38, rate: 1, vpn: true, image: 'https://globalcsgo.com/wp-content/uploads/2021/01/key-drop-logo.png',info: 'csgo5_info', link: 'https://csgo5.run/ref/zwqyco', promo: 'zwqyco', tags: ['roulette', 'crash' ]}, <--- НУ ЭТО ВООБЩЕ ПИЗДЕЦ
    // {key: 39, rate: 1, vpn: true, image: 'https://globalcsgo.com/wp-content/uploads/2021/01/key-drop-logo.png',info: 'loot3_info', link: 'https://loot3.run/ref/PODLIVA', promo: 'PODLIVA', tags: ['roulette', 'crash' ]}, <--- НУ ЭТО ВООБЩЕ ПИЗДЕЦ
    // {key: 40, rate: 1, vpn: true, image: 'https://globalcsgo.com/wp-content/uploads/2021/01/key-drop-logo.png',info: 'topskin_info', link: 'https://topskin.cc', promo: 'PODLIV', tags: ['roulette', 'crash' ]}, <--- НУ ЭТО ВООБЩЕ ПИЗДЕЦ

    // НЕ КС САЙТЫ, ПОЗЖЕ ДОБАВИМ БТВ ПОД НИХ РАЗДЕЛ, А ТАК ЧИСТО ИХ ОФОРМИМ
    {key: 41, rate: 2.5, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/godota2-logo.png',info: 'godota2_info', link: 'https://ify.ac/z2n', promo: 'PODLIVA', tags: ['CS2', 'DOTA 2', 'roulette' ]},
    // https://godota2.com
    // {key: 42, rate: 2.5, vpn: true, image: 'https://cs2skinsfree.ru/images/sites-icons/banditcamp-logo.png',info: 'banditcamp_info', link: 'https://bandit.camp/r/podliva', promo: 'podliva', tags: ['roulette', 'crash' ]}, <--- Я ВООБЩЕ НЕ ПОНЯЛ, ЧТО ЭТО ЗА САЙТ. ВРОДЕ ПРОСТО КАЗИНО

    // ЭТОГО САЙТА ВООБЩЕ НЕ СУЩЕСТВУЕТ, НО МНЕ НУЖЕН БЫЛ САЙТ ПО РАСТУ ДЛЯ ТЕСТОВ
    // {key: 1488, rate: 0.5, vpn: false, image: 'https://cs2skinsfree.ru/images/sites-icons/gamdom-logo.png', info: 'gamdom_info', link: 'https://gamdom.com/r/PODLIVA', promo: 'PODLIVA', tags: ['Rust', 'gambling', 'rakeback', 'roulette', 'crash', 'match-betting', 'slots', 'hilo', 'raffle']},
]

export const tagList = [
    // {name: 'games', tags: ['CS2', 'DOTA2', 'RUST']},
    {name: 'site_types', tags: ['trade', 'gambling', 'cashout']},
    {name: 'bonuses', tags: ['bonus_deposit', 'bonus_balance', 'bonus_case', 'rakeback']},
    {name: 'game_modes', tags: ['roulette', 'crash', 'match-betting', 'slots', 'hilo', 'raffle','battle', 'plinko', 'pocker', 'blackjack', 'minesweeper', 'cases', 'dice', 'upgrader','coinflip', 'duels', 'slots']}
]

