import React, {Suspense} from "react";
import {
    Button,
    useToast, Box, Text, Image, Flex, Link, Icon
} from "@chakra-ui/react";
import { list } from "../List";
import { BsFillCheckSquareFill } from "react-icons/bs";
import {GoCopy} from 'react-icons/go'
import '../../fonts/fonts.css'
import StarsComponent from './SubModules/Stars'
import {useTranslation} from "react-i18next";
import Loader from "./SubModules/Loader";

export const TagElement = ({item, ...params}) => {
    const { t, i18n } = useTranslation();
    return (
        <Flex flexDir='row' alignItems='center' justifyContent='center' flexWrap='warp'{...params}>
            <Icon as={BsFillCheckSquareFill} color='podliva.700'/>
            <Text textShadow='1px 2px black' ml='1' fontSize='16px' textColor='podliva.700' fontWeight='500'> {t(item)} </Text>
        </Flex>
    )
}

export const SiteElement = ({item, ...params}) => {
    let toast = useToast()
    const { t, i18n } = useTranslation();
    const copyPromocode = (item) => {

        navigator.clipboard.writeText(item.promo)
        toast({
            title: t('promo_copied_title'),
            description: t('promo_copied_description'),
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
    }
    const VPNElement = ({item}) => {
        let userLang = navigator.language
        if (item.vpn == true && userLang == 'ru') {
            return (
                <Flex 
                    w='12%'
                    zIndex='5'
                    p='4px'
                    position='absolute'
                    // textAlign='end'
                    justify='center'
                    color='#ffffff'
                    top="3.5%"
                    left='-4.5%'
                    boxShadow='lg'
                    transform='rotate(-45deg)'
                    bg='podliva.500'>
                    <Text userSelect='none' color='podliva.700' textShadow='1px 2px black'>
                        VPN
                    </Text>
                </Flex>
            )
        }
    }

    return (
        <Flex 
            w='80%' 
            h='120px' 
            overflow='hidden'
            bgGradient='linear(to-r, podliva.300, podliva.200)'
            mb='1.5%' 
            pos='relative'
            borderRadius='10px' 
            alignItems='center'
            boxShadow='dark-lg'
            maxW='2000px'
            {...params}
            >
            <VPNElement item={item}/>
            <Flex h='100%' w='22%' bg='podliva.200' alignItems="center"  justifyContent="flex-start" pl='3%' borderRadius='10px' clipPath='polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 49%, 0% 0%)'  >
            {/* clipPath='polygon(75% 0%, 100 50%, 75% 100%, 0% 100%, 0 49%, 0% 0%)' */}

                <Image src={item.image}  htmlWidth='65%'></Image>
            </Flex>
            <Box w='50%' h='100%' >

                <Flex w='100%' h='100%'  flexDir='column' p='1%'  >
                    <Box  mt='1.5%' >
                        <Text  fontSize='18px' textColor='whiteAlpha.900' fontFamily="'PT Sans', sans-serif" fontWeight='400'>{t(item.info)}</Text>
                    </Box>

                    <Flex w='70%' h='10%' alignSelf='flex-start' justify='flex-start' mt='5%' >
                            {item.tags.slice(0,5).map((item)=> {
                                return (<TagElement mr='5' fontSize='15px'  item={item}/>)})
                            }
                    </Flex>
                </Flex>
            </Box>
            <Box w='7%' h='50%' mr='5%'>
                <Flex w='100%' pl='50%' mb='7%' justifyContent='center'>
                    <Text textShadow='1px 1px black' color='podliva.600'>
                        {t('rating')}:
                    </Text>
                </Flex>
                <StarsComponent count={item.rate} />
            </Box>
                
            {/* <Text>{item.title}</Text> */}
            <Flex w='20%' justifyContent='flex-end' mr='15px'>
                <Box>
                    <Flex  justifyContent='center' alignItems='center' bg='podliva.600' borderRadius='6px' mb='2%'>
                        <Text  color='podliva.100' textDecor='underline'_hover={{cursor: 'pointer'}} onClick={() => copyPromocode(item)}>{item.promo.slice(0,10)}</Text>
                        <Icon as={GoCopy} color='podliva.100'/>
                    </Flex>
                    <Link href={item.link}>
                        <Button fontSize='14px' color='podliva.100' bg='podliva.700' size='md'> {t('take_your_reward')}</Button>
                    </Link>
                </Box>
            </Flex>


        </Flex>
    )
}

export default function SiteList ({siteList}) {


    return (
            <Box  mt='2%' p='1%' h='100%' w='80%' >
                {siteList.map((item) => {
                    return <SiteElement item={item} />
                })}
                <Flex
                h={(siteList.length < 7) ? '1500px' : '0px'}
                 >
                    <Text color='podliva.600'>

                    </Text>
                </Flex>
            </Box>
    );
};