import React from 'react';
import Meta from '../MetaTagsList';
import { Helmet } from "react-helmet";

const MetaTagsConstructor = (props) => {

    let content = Meta.find( item => item.url === props.url );
    if ( ! content ) {
        content = Meta.find( item => item.url === 'default' );
    }
    const metaContent =
        content.url !== "default"
            ? "https://cs2skinsfree.ru/" + content.url
            : "https://cs2skinsfree.ru/";

    return (
        <Helmet>
            <link rel="alternate" hrefLang="x-default" href={"https://cs2skinsfree.ru/" + content.url}/>

            <title>{content.title}</title>
            <meta name="coverage" content="Worldwide"/>
            <meta name="description" content={ content.description } />
            <meta name="keywords" content={ content.keywords } />

            <meta name="DC.Title" content={content.title}/>
            <meta name="DC.Creator" content="cs2skinsfree.ru"/>
            <meta name="DC.Subject" content={ content.keywords }/>
            <meta name="DC.Description" content={content.description}/>
            <meta name="DC.Publisher" content="cs2skinsfree.ru"/>
            <meta name="DC.Contributor" content="cs2skinsfree.ru"/>
            <meta name="DC.Date" content="2023-10-17"/>
            <meta name="DC.Type" content="Service"/>
            <meta name="DC.Format" content="text/html"/>
            <meta name="DC.Identifier" content={metaContent}/>
            <meta name="DC.Source" content="https://cs2skinsfree.ru"/>
            <meta name="DC.Provenance" content="https://cs2skinsfree.ru"/>
            <meta name="DC.Rights" content="https://cs2skinsfree.ru"/>

            <meta property="og:title" content={ content.title }/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://cs2skinsfree.ru/" />
            <meta property="og:image" content="https://cs2skinsfree.ru/images/Ded.png" />
            <meta property="og:image:alt" content="get free CS:GO skins" />
            <meta property="og:site_name" content="cs2skinsfree"/>
            <meta property="og:description" content={ content.description }/>

        </Helmet>
    );

}


export default MetaTagsConstructor;