import React, {lazy, useState} from 'react';
import {Box, Flex, Icon, Text, Collapse, useDisclosure, RadioGroup, Stack, Radio, Center} from "@chakra-ui/react";
import { list, tagList } from '../List';
import { BsFillArrowDownSquareFill , BsFillArrowUpSquareFill } from 'react-icons/bs';
import {useTranslation} from "react-i18next";
const FilterElement = ({text, activeFilters, setActiveFilters, setSiteList}) => {
    const { t, i18n } = useTranslation();
    const [active, setActive] = useState(false)

    const isActive = () => {
        setActive(true)
        let tempFilters = [...activeFilters, text]
        setActiveFilters(tempFilters)
        let tempSiteList = [...list]
        console.log('Перед фильтрацией :', tempFilters)
        tempSiteList = tempSiteList.filter((site) => {
            return tempFilters.every(filter => site.tags.includes(filter));
        });
        

        setSiteList(tempSiteList)
        console.log('Активные фильтры: ', tempFilters, tempSiteList)
    }
    const isDeactive = () => {
        setActive(false)
        let tempSiteList = [...list]
        let tempFilters = activeFilters.filter((item) => {
            if (item != text) {
                return item
            }
        })
        setActiveFilters(tempFilters)
        console.log('Перед фильтрацией isDeactive :', tempFilters)
        if (tempFilters == []) {
            setSiteList(list) 
            return
        } 


        tempSiteList = tempSiteList.filter((site) => {
            return tempFilters.every(filter => site.tags.includes(filter));
        });
        setSiteList(tempSiteList)
        console.log('Активные фильтры isDeactive: ', tempFilters)
    }
    return (
        <Flex 
            w='45%' 
            h='60%' 
            borderRadius='6px' 
            alignItems='center' 
            justifyContent='space-around'
            m="1%"
            p='2px'
            align='center'
            justify='center'
            bg={active ? 'podliva.800' : 'podliva.600'}
            onClick={() => {active?  isDeactive() : isActive()}}
            _hover={{cursor: 'pointer'}}
        
        >
            <Text  fontSize='14px' userSelect='none' textAlign='center' color='podliva.300'>
                {t(text)}
            </Text>
        </Flex>
      )

}

const FilterSection = ({item, activeFilters, setActiveFilters, setSiteList}) => {
    const { t, i18n } = useTranslation();
    return (
        <Box mb='6%'>
            <Text textAlign='center' color='podliva.700'>{t(item.name)}</Text>
            <Flex
                    flexWrap='wrap'
                    justify='space-between'
                >
                    {item.tags.map((item) => {
                        return (
                            <FilterElement text={item} activeFilters={activeFilters} setActiveFilters={setActiveFilters} setSiteList={setSiteList}/>
                        )
                    })}
                </Flex>
        </Box>

    )
}

export default function Filter({setSiteList, siteList}) {
    const [activeFilters, setActiveFilters] = useState([])
    const {isOpen, onToggle} = useDisclosure()
    const [type, setType] = useState('CS2')
    const { t, i18n } = useTranslation();
    const Arrow = ({isOpen}) => {
        return (
            <Flex w='5%' pl='2%' justify='center'>
                <Icon onClick={onToggle} as={ isOpen ? BsFillArrowUpSquareFill : BsFillArrowDownSquareFill} color='podliva.700'/>
            </Flex>
        )
    }

    const setMainFilter = (value) => {
        setType(value)
        let tempFilters = [...activeFilters, value]
        let inactiveMainFilters = ['CS2', 'DOTA 2', 'Rust'].filter(item => item != value)
        tempFilters = tempFilters.filter(item => !inactiveMainFilters.includes(item))
        setActiveFilters(tempFilters)
        let tempSiteList = [...list]
        tempSiteList = tempSiteList.filter((site) => {
            return tempFilters.every(filter => site.tags.includes(filter));
        });
        setSiteList(tempSiteList)
    }
    // const [showArrow, setShowArrow] = useState(<Arrow/>)
    return (
        <Flex
            w='16%' 
            h='40%' 
            alignItems='flex-start' 
            // justify='center'  
            mt='3%' 
            ml='2%'
            p='5px'
            flexDir='row' 
            flexWrap='wrap'
            bg='podliva.200'

            borderRadius='10px'
            boxShadow='dark-lg'
            >
            <Flex w='100%' justify='center' align='center'>
                <Text textAlign='center' color='podliva.500'> {t('filter')} </Text>
                <Arrow isOpen={isOpen}/>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <Box>
                <Center textColor='podlia.300' flexDir='column'>
                    <Text color='podliva.700'>
                        {t('main_game')}
                    </Text>
                    <RadioGroup colorScheme='white' onChange={setMainFilter} value={type}>
                        <Stack color='podliva.600' direction='row'>
                            <Radio value='CS2'>CS2</Radio>
                            <Radio value='DOTA 2'>DOTA 2</Radio>
                            <Radio value='RUST'>RUST</Radio>
                        </Stack>
                    </RadioGroup>
                </Center>

                    {tagList.map((item) => {
                        return (<FilterSection item={item} activeFilters={activeFilters} setActiveFilters={setActiveFilters} setSiteList={setSiteList}/>)
                    })}
                </Box>
                {/* <Flex
                    flexWrap='wrap'
                >
                    {tagList.map((item) => {
                        return (
                            <FilterElement text={item} activeFilters={activeFilters} setActiveFilters={setActiveFilters} setSiteList={setSiteList}/>
                        )
                    })}
                </Flex> */}
            </Collapse>


            <Flex>


            </Flex>
        </Flex>
    )
}