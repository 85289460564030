import React, {useEffect} from "react";
import {Box, Flex, Text} from "@chakra-ui/react";
import { list } from "../List";
import '../../fonts/fonts.css'
import { SiteElement } from "../Modules/SiteList";
import {useTranslation} from "react-i18next";
import MetaTagsConstructor from "../Modules/MetaTagsConstructor";
export default function BestPage() {
    const { t, i18n } = useTranslation();
    // useEffect(() => {
    //     document.title = 'Лучшие сайты';
    //   }, []);
    return (
        <Flex as='main' justify='center' align='center' flexWrap='warp'>            
            <Text w='0' visibility='hidden' as='h1'>{t('best_h1')}</Text>
            <Text w='0' visibility='hidden' as='h2'>{t('best_h2')}</Text>
            <Box  mt='2%' p='1%' h='100%' w='80%' >
                <Flex justify='center' flexDir='column'>
                    <Box>
                        
                    </Box>
                    {list.map((item) => {
                        if (item.rate === 5) {
                            return <SiteElement item={item} justify='center' />
                        }
                    })}
                </Flex>
            </Box>

            <MetaTagsConstructor url="bestPage" />

        </Flex>

    );

}