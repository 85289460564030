import React from "react";
import {
    Select,
    Flex,
} from "@chakra-ui/react";
import '../../../fonts/fonts.css'
import ReactCountryFlag from "react-country-flag";

export default function LanguageSelector({i18n, ...params}) {
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        console.log("Language Changed to: " + lng)
    };
    return (
        <Flex {...params} alignSelf='center' ml='5%' mr='1px'>
            <Select bg='podliva.600' onChange={e => changeLanguage(e.target.value)}>
                <option value="" selected disabled hidden><ReactCountryFlag countryCode="EN" title="ENG"/> </option>
                <option value='en'>
                    <ReactCountryFlag countryCode="EN" title="ENG"/> 
                </option>
                <option value='ru'>
                    <ReactCountryFlag countryCode="RU" title="RUS"/> 
                </option>
                <option value='de'>
                    <ReactCountryFlag countryCode="DE" title="GER"/>
                </option>
                <option value='br'>
                    <ReactCountryFlag countryCode="BR" title="BRA"/>
                </option>
                <option value='cn'>
                    <ReactCountryFlag countryCode="CN" title="CHN"/>
                </option>
                <option value='es'>
                    <ReactCountryFlag countryCode="ES" title="ESP"/>
                </option>
                <option value='fr'>
                    <ReactCountryFlag countryCode="FR" title="FRA"/>
                </option>
                <option value='jp'>
                    <ReactCountryFlag countryCode="JP" title="JAP"/>
                </option>
                <option value='ni'>
                    <ReactCountryFlag countryCode="NI" title="NID"/>
                </option>
                <option value='pl'>
                    <ReactCountryFlag countryCode="PL" title="POL"/>
                </option>
                <option value='se'>
                    <ReactCountryFlag countryCode="SE" title="SWE"/>
                </option>

            </Select>
        </Flex>

    )
}