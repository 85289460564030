import React from "react";
import {Flex, Icon} from "@chakra-ui/react";
import '../../../fonts/fonts.css'
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";

// 

export default function StarsComponent ({count, ...params}) {
    let stars, emptyStars, halfStars
    const starSize = '30%'
    if (count % 1 === 0) {
        stars = Array.from({ length: count }, (value, index) => (
            <Icon as={BsStarFill} color='podliva.700' key={index} boxSize={starSize}/>
        ))
        emptyStars = Array.from({ length: 5-count }, (value, index) => (
            <Icon as={BsStar} color='podliva.700' key={index} boxSize={starSize} />
        ))
        return (
            <Flex  {...params}>
                {stars}{emptyStars}
            </Flex>
            )
    } else {
        stars = Array.from({ length: count }, (value, index) => (
            <Icon as={BsStarFill} color='podliva.700' key={index}boxSize={starSize} />
        ))
        emptyStars = Array.from({ length: 4.5-count }, (value, index) => (
            <Icon as={BsStar} color='podliva.700' key={index} boxSize={starSize}/>
        ))
        halfStars = <Icon as={BsStarHalf} color='podliva.700' key={10} boxSize={starSize}/>
        return (
            <Flex  {...params}>
                {stars}{halfStars}{emptyStars}
            </Flex>
            )
    }

}