import React, {useEffect} from "react";
import {Box, Text, Image, Flex,Heading} from "@chakra-ui/react";
import '../../fonts/fonts.css'
import {useTranslation} from "react-i18next";
import MetaTagsConstructor from "../Modules/MetaTagsConstructor";


export default function GuidePage() {
    const { t, i18n } = useTranslation();
    // useEffect(() => {
    //     document.title = 'Советы CS2';
    //   }, []);
    return (
        <Flex justify='center' align='flex-start' flexWrap='warp' >
            <Text w='0' visibility='hidden' as='h1'>{t('guide_h1')}</Text>
            <Text w='0' visibility='hidden' as='h2'>{t('guide_h2')}</Text>
            <Box
                w='70%'
                // borderColor="podliva.400" 
                // borderWidth='2px' 
                // borderRadius='10px' 
                mt='3%'
                >
                    
                <Flex 
                    justify='flex-start' 
                    bg='podliva.300' 
                    p='1%'
                    borderTopRadius='10px' 
                    alignItems='center'
                    flexDir='column'
                    boxShadow='dark-lg'>
                    <Heading as='h1' size='xl' color='podliva.800' w='50%' textShadow='0 0 20px black' >
                        {t('guide_header')}
                    </Heading>
                    <Image boxSize='70%' boxShadow='outline' borderRadius='10px' mt='1%' src="https://cybersport.metaratings.ru/storage/images/c8/0b/c80bb0fa38cbf5d1d0ec7b613bad6bf9.png"/>
                </Flex>
                
                <Box
                    flexWrap='warp' 
                    w='100%' 
                    bg='podliva.300'
                    p='1%'
                    borderBottomRadius='10px' 
                    alignItems='center'>
                    <Text pl='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        {t('guide_text1')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('guide_heading1')}
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        {t('guide_text2')}
                        CS2 — это видеоигра, которую можно приобрести на Steam, игровой платформе от Valve. Для ее получения потребуется создать учетную запись, зайти в магазин Steam и найти игру по ее названию. Важно отметить, что игроки, уже приобретшие CS:GO, получат CS2 автоматически без дополнительных затрат, так как она будет автоматически добавлена в их аккаунты.
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('guide_heading2')}
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        {t('guide_text3')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('guide_heading3')}
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        {t('guide_text4')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('guide_heading4')}
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        {t('guide_text5')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('guide_heading5')}
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        {t('guide_text6')}
                    </Text>
                    <Heading as='h2' size='lg' color='podliva.800'>
                        {t('guide_heading6')}
                    </Heading>
                    <Text pl='1%' pt='1%' pb='1%' color='podliva.600' fontFamily="'PT Sans', sans-serif">
                        {t('guide_text7')}
                    </Text>
                </Box>

                <MetaTagsConstructor url="guidePage" />

            </Box>

        </Flex>
    )

}